import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Card, Button, Form, Row, Col } from 'react-bootstrap';
import ApiService from '../services/apiService';
import SeoService from '../services/seoService';
import CommunService from '../services/communService';

const LoginPage = () => {
    const [pseudo, setPseudo] = useState('');
    const [password, setPassword] = useState('');
    const [new_pseudo, setNewPseudo] = useState('');
    const [new_password, setNewPassword] = useState('');

    const login = (e) => {
        e.preventDefault();
        ApiService.postData('login', { pseudo: pseudo, password: password }).then((response) => {
            sessionStorage.setItem('player', JSON.stringify(response.player));
            sessionStorage.setItem('token', response.token);
            window.location.href = '/';
        });
    };

    const signup = (e) => {
        e.preventDefault();
        ApiService.postData('signup', { pseudo: new_pseudo, password: new_password }).then((response) => {
            CommunService.successPopup('Votre compte a bien été créé. Vous pouvez vous connecter.');
            handleClose();
        });
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    SeoService.setTitle('Connexion')

    return (
        <div className='animate__animated animate__fadeIn'>
            <Card className='text-center mb-4 m-auto' style={{ 'max-width': '500px', 'padding': '15px' }}>
                <Card.Body>
                    <Card.Title>UEFA EURO 2024</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Application de pronostiques</Card.Subtitle>
                    <Card.Text>
                        <img className='mt-3 mb-3' height={150} src='https://upload.wikimedia.org/wikipedia/fr/thumb/0/0a/Logo_UEFA_Euro_2024.svg/1613px-Logo_UEFA_Euro_2024.svg.png' alt='Logo UEFA EURO 2024' />
                        <Form onSubmit={(e) => { login(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Votre pseudo :</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={pseudo}
                                    onChange={(e) => setPseudo(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Votre mot de passe :</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className='mt-2'>Me connecter</Button>
                        </Form>
                    </Card.Text>
                    <Card.Link as={Link} to="#" onClick={handleShow}>M'inscrire</Card.Link>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose} className='text-black'>
                <Form onSubmit={(e) => { signup(e) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>S'incrire</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Votre pseudo :</Form.Label>
                            <Form.Control
                                type="text"
                                value={new_pseudo}
                                onChange={(e) => setNewPseudo(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Votre mot de passe :</Form.Label>
                            <Form.Control
                                type="password"
                                value={new_password}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" type='submit' onClick={handleClose}>
                            M'inscrire
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default LoginPage;
