import React, { useEffect, useState } from 'react';
import { Table, Alert } from 'react-bootstrap';
import ApiService from '../services/apiService';
import FormatService from '../services/formatService';
import SeoService from '../services/seoService';

const MesPronostiques = () => {
  const [matchs, setMatchs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMatchs = async () => {
      try {
        const response = await ApiService.getData('players/me/pronostics/');
        setMatchs(response);
      } catch (error) {
        console.error('Error fetching matchs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatchs();
  }, []);

  SeoService.active('Pronostics');
  SeoService.setTitle('Mes pronostics');

  if (loading) {
    return <p>Chargement en cours ...</p>;
  }

  return (
    <div className='animate__animated animate__fadeIn'>
      <h1>Mes pronostics</h1>
      <p>Voici la liste des matchs avec mes pronostics :</p>
      <Table responsive striped bordered hover className='text-center align-middle'>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Equipe 1</th>
            <th>Mon pronostic</th>
            <th>Score Final</th>
            <th>Equipe 2</th>
            <th>Phase / Groupe</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {matchs.map((match, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{FormatService.datetime_fr(match.match.start_at)}</td>
              <td>{match.match.team_a?.name ?? 'Non défini'}</td>
              <td>
                {match.score_a} - {match.score_b}
                <div>{match.prolongation ? 'Prolongations' : ''}</div>
                <div>{match.tab ? `${match.tab_a} TAB ${match.tab_b}` : ''}</div>
              </td>
              <td>
                {match.match.score_a} - {match.match.score_b}
                <div>{match.match.prolongation ? 'Prolongations' : ''}</div>
                <div>{match.match.tab ? `${match.match.tab_a} TAB ${match.match.tab_b}` : ''}</div>
              </td>
              <td>{match.match.team_b?.name ?? 'Non défini'}</td>
              <td>{match.match.phase?.name} / {match.match.group?.name}</td>
              <td>{match.points}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default MesPronostiques;