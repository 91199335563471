import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../services/apiService';
import { Card, Table, Alert } from 'react-bootstrap';
import FormatService from '../services/formatService';
import SeoService from '../services/seoService';

const Home = () => {
    const [matchs, setmatchs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchmatchs = async () => {
            try {
                const response = await ApiService.getData('matchs/today');
                setmatchs(response);
            } catch (error) {
                console.error('Error fetching matchs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchmatchs();
    }, []);

    SeoService.active('Home');
    SeoService.setTitle('Matchs du jour');

    if (loading) {
        return <p>Chargement en cours ...</p>;
    }

    return (
        <div>
            <Card className='text-center mb-4 animate__animated animate__fadeIn'>
                <Card.Body>
                    <Card.Title>UEFA EURO 2024</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Application de pronostiques</Card.Subtitle>
                    <Card.Text>
                        <img className='mt-3' height={150} src='https://upload.wikimedia.org/wikipedia/fr/thumb/0/0a/Logo_UEFA_Euro_2024.svg/1613px-Logo_UEFA_Euro_2024.svg.png' alt='Logo UEFA EURO 2024' />
                    </Card.Text>
                    <Card.Link as={Link} to="/regles">Voir les règles</Card.Link>
                </Card.Body>
            </Card>
            <Card className='text-center mb-4 animate__animated animate__fadeIn'>
                <Card.Body>
                    <Card.Title>Matchs du jour</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Liste des matchs du jour :</Card.Subtitle>
                    <Card.Text>
                        <Table responsive striped bordered hover className='mt-3 text-center align-middle'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Equipe 1</th>
                                    <th>Score</th>
                                    <th>Equipe 2</th>
                                    <th>Phase & Groupe</th>
                                    <th>Cote</th>
                                </tr>
                            </thead>
                            <tbody>
                                {matchs.map((matchs, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{FormatService.datetime_fr(matchs.start_at)}</td>
                                        <td>{matchs.team_a?.name ?? 'Non défini'}</td>
                                        <td>{matchs.score_a} - {matchs.score_b}</td>
                                        <td>{matchs.team_b?.name ?? 'Non défini'}</td>
                                        <td>{matchs.phase?.name} {matchs.group?.name}</td>
                                        <td>{matchs.victoires_A} - {matchs.nuls} - {matchs.victoires_B}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Text>
                    <Card.Link as={Link} to="/parier" className='btn btn-primary'>Parier</Card.Link>
                </Card.Body>
            </Card>

        </div>
    );
}

export default Home;
