
import Swal from 'sweetalert2'

const CommunService = {

    successPopup: (message) => {
        Swal.fire({
            title: "Opération réussie",
            text: message,
            icon: "success"
          });
    },
    errorPopup: (message) => {
        Swal.fire({
            title: "Avertissement",
            text: message,
            icon: "error"
          });
    }
}

export default CommunService;