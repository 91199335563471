import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import SeoService from '../services/seoService';

const Regles = () => {

    SeoService.setTitle('Règles');

    return (
        <div className='animate__animated animate__fadeIn'>
            <Card className='text-center mb-4'>
                <Card.Body>
                    <Card.Title>UEFA EURO 2024</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Application de pronostiques</Card.Subtitle>
                    <Card.Text>
                        <img className='mt-3' height={150} src='https://upload.wikimedia.org/wikipedia/fr/thumb/0/0a/Logo_UEFA_Euro_2024.svg/1613px-Logo_UEFA_Euro_2024.svg.png' alt='Logo UEFA EURO 2024' />
                        <h2 className='mt-5'>Les règles</h2>
                        <div className='text-start'>
                            <h3 className='mt-5'>Parier</h3>
                            <p>Il est possible de parier pour chaque match du jour tant que le match n'a pas démarré.</p>
                            <p>Le vendredi, vous pouvez voter si vous le souhaitez pour tous les matchs du week-end en plus des matchs du jour.</p>
                            <p>Une fois votre pronostic validé, il est sauvegardé et ne sera plus modifiable par la suite.</p>
                            <p>Le vote est possible jusqu'à une seconde avant le début du match (heure de la rencontre).</p>
                            <p>Les résultats seront saisis au fur et à mesure ou en une seule fois à la fin de la journée ou le lendemain matin.</p>

                            <h3 className='mt-5'>Saisie des paris</h3>
                            <p>Pendant les phases de groupes, vous ne pouvez saisir que le score A et B uniquement, car il n'y a ni prolongations, ni tirs au but.</p>
                            <p>Saisir le score de l'équipe A puis de l'équipe B.</p>
                            <p>Pendant les phases finales, vous devez saisir le score A et B et prédire s'il y aura des prolongations et des tirs au but.</p>
                            <p>Saisir le score de l'équipe A puis de l'équipe B.</p>
                            <p>Vous pouvez parier qu'il y aura des prolongations.</p>
                            <p>Vous pouvez parier qu'il y aura des tirs au but suite aux prolongations.</p>
                            <p>Dans ce cas, vous devez saisir également le score des tirs au but.</p>
                            <p>Une fois que le score vous convient, vous pouvez le valider.</p>
                            <p>Vous pariez match par match, pour éviter de potentielles erreurs.</p>

                            <h3 className='mt-5'>Calcul des scores</h3>
                            <p>Pour gagner des points, vous devez avoir trouvé le vainqueur du match dans tous les cas.</p>
                            <p><strong>Pour tous les matchs :</strong></p>
                            <ul>
                                <li>Vous avez trouvé le score exact (<strong>+10 points</strong>)</li>
                                <li>Vous avez trouvé la bonne différence de buts (<strong>+5 points</strong>)</li>
                                <li>Vous avez trouvé un score exact pour A ou B (<strong>+3 points</strong>)</li>
                                <li>Vous avez trouvé uniquement le vainqueur (<strong>+1 point</strong>)</li>
                            </ul>

                            <p><strong>Pour les phases finales :</strong></p>
                            <ul>
                                <li>Vous avez parié que le match aurait des prolongations ?</li>
                                <ul>
                                    <li>Il y en a eu ? (<strong>+3 points</strong>)</li>
                                    <li>Il n'y en a pas eu ? (<strong>-2 points</strong>)</li>
                                </ul>
                                <li>Vous avez parié que le match aurait des tirs au but ?</li>
                                <ul>
                                    <li>Il y en a eu et vous avez trouvé le bon score ? (<strong>+20 points</strong>)</li>
                                    <li>Il y en a eu et vous avez trouvé la bonne différence de score ? (<strong>+10 points</strong>)</li>
                                    <li>Il y en a eu et vous avez trouvé le vainqueur ? (<strong>+5 points</strong>)</li>
                                    <li>Il n'y en a pas eu ? (<strong>-3 points</strong>)</li>
                                </ul>
                            </ul>

                            <p><strong>Prenons quelques exemples :</strong></p>
                            <ul>
                                <li>Score final : <strong>5-0</strong> dans une phase de groupe</li>
                                <ul>
                                    <li>Vous avez parié 5-0 ? (<strong>+10 points</strong>)</li>
                                    <li>Vous avez parié 6-1 ? (<strong>+5 points</strong>)</li>
                                    <li>Vous avez parié 5-3 ? (<strong>+3 points</strong>)</li>
                                    <li>Vous avez parié 1-0 ? (<strong>+1 point</strong>)</li>
                                    <li>Vous avez parié 0-5 ou 1-6 ou 3-5 ou 0-1 ? (<strong>Perdu</strong>)</li>
                                </ul>
                                <li>Score final : <strong>2-1</strong> sans prolongations dans une phase finale</li>
                                <ul>
                                    <li>Vous aviez parié 2-1 avec prolongations ? (<strong>10pts - 2pts soit 8pts</strong>)</li>
                                    <li>Vous aviez parié 2-2 avec tirs au but 5-4 ? (<strong>1pt - 2pts - 3pts soit -4pts</strong>)</li>
                                </ul>
                                <li>Score final : <strong>2-2</strong> avec tirs au but <strong>5-1</strong> dans une phase finale</li>
                                <ul>
                                    <li>Vous aviez parié 2-1 avec prolongations ? (<strong>3pts + 2pts soit 5pts</strong>)</li>
                                    <li>Vous aviez parié 2-2 avec tirs au but 5-1 ? (<strong>10pts + 3pts + 20pts soit 33pts</strong>)</li>
                                    <li>Vous aviez parié 2-2 avec tirs au but 5-4 ? (<strong>10pts + 3pts + 5pts soit 18pts</strong>)</li>
                                </ul>
                            </ul>
                        </div>
                    </Card.Text>
                    <Card.Link as={Link} to="/">Retour à la page d'accueil</Card.Link>
                </Card.Body>
            </Card>

        </div>
    );
}

export default Regles;
