import axios from 'axios';
import CommunService from './communService';

const API_BASE_URL = 'https://api.tpr17.fr/api/euro2024/';

const ApiService = {
  async api(url, method = 'GET', post = null) {
    // Configuration initiale de la requête
    const config = {
      url: url.startsWith('http') ? url : `${API_BASE_URL}${url}`,
      method: method,
    };

    // Ajout de la clé d'autorisation à la configuration
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
      };
    }

    // Préparation des données pour les requêtes POST
    if (post) {
      const formData = new FormData();
      const jsonData = {};

      for (const key in post) {
        if (post.hasOwnProperty(key)) {
          if (post[key] instanceof File) {
            formData.append(key, post[key]);
          } else if (post[key] instanceof FileList) {
            // Traitement des listes de fichiers
            for (let i = 0; i < post[key].length; i++) {
              formData.append(`${key}[]`, post[key][i]);
            }
          } else if (Array.isArray(post[key])) {
            let newTable = [];
            post[key].forEach(element => {
              // On vérifie si la clé est un fichier
              if (element instanceof File) {
                formData.append(`${key}[]`, element);
              } else {
                newTable.push(element);
              }
            });
            // Conversion et ajout des tableaux au JSON
            jsonData[key] = newTable; // ou utilisez une fonction similaire à cleanJson si nécessaire
          } else {
            // Ajout des autres types de données au JSON
            jsonData[key] = post[key];
          }
        }
      }

      // Ajout des données JSON à formData
      formData.append('jsonData', JSON.stringify(jsonData));
      config.data = formData;
      // Axios gère automatiquement l'entête 'Content-Type' pour les FormData
    }

    try {
      const response = await axios(config);
      if (response.data.error) {
        throw response.data;
      } else if (response.data === "") {
        throw { error: 'La réponse est invalide.' };
      }
      return response.data;
    } catch (error) {
      console.error('Une requête a échouée', url, method, post, error);
      throw CommunService.errorPopup(error.response.data.error ?? "Une erreur est survenue.");
    }
  },

  getData: (url) => {
    return ApiService.api(url, 'GET');
  },

  postData: (url, data) => {
    return ApiService.api(url, 'POST', data);
  },
};

export default ApiService;
