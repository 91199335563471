import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import ApiService from '../services/apiService';
import FormatService from '../services/formatService';
import SeoService from '../services/seoService';

const Matchs = () => {
  const [matchs, setMatchs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMatchs = async () => {
      try {
        const response = await ApiService.getData('matchs/');
        setMatchs(response);
      } catch (error) {
        console.error('Error fetching matchs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatchs();
  }, []);

  SeoService.active('Matchs');
  SeoService.setTitle('Matchs');

  if (loading) {
    return <p>Chargement en cours ...</p>;
  }

  return (
    <div className='animate__animated animate__fadeIn'>
      <h1>Matchs</h1>
      <p>Voici la liste des matchs :</p>
      <Table responsive striped bordered hover align='center' className='text-center align-middle'>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Equipe 1</th>
            <th>Score</th>
            <th>Equipe 2</th>
            <th>Phase / Groupe</th>
          </tr>
        </thead>
        <tbody>
          {matchs.map((match, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{FormatService.datetime_fr(match.start_at)}</td>
              <td>{match.team_a?.name ?? 'Non défini'}</td>
              <td>{match.score_a} - {match.score_b}</td>
              <td>{match.team_b?.name ?? 'Non défini'}</td>
              <td>{match.phase?.name} / {match.group?.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Matchs;