import React, { useEffect, useState } from 'react';
import { Table, Alert } from 'react-bootstrap';
import ApiService from '../services/apiService';
import FormatService from '../services/formatService';
import SeoService from '../services/seoService';

const Classement = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await ApiService.getData('players/classement');
        setPlayers(response);
      } catch (error) {
        console.error('Error fetching players:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  SeoService.active('Classement');
  SeoService.setTitle('Classement');

  if (loading) {
    return <p>Chargement en cours ...</p>;
  }

  return (
    <div className='animate__animated animate__fadeIn'>
      <h1>Classement</h1>
      <p>Voici les résultats du classement actuel :</p>
      <Table responsive striped bordered hover align='center' className='text-center align-middle'>
        <thead>
          <tr>
            <th>#</th>
            <th>Joueur</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{player.pseudo}</td>
              <td>{player.score}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Classement;