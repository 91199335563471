const dayjs = require('dayjs')

const FormatService = {
    date_fr: (date) => {
        return dayjs(date).locale('fr').format('DD/MM/YYYY');
    },
    datetime_fr: (date) => {
        return dayjs(date).locale('fr').format('DD/MM/YYYY à HH:mm').replace(':', 'h');
    }
}

export default FormatService;