
const SeoService = {
    active: (id) => {
        document.querySelectorAll('.navbar .nav-link').forEach(element => {
            element.classList.remove('active');
        });
        document.querySelector(`#nav${id}`)?.classList.add('active');
    },
    setTitle: (title) => {
        document.title = `${title} - EURO 2024 by TPR`;
    }
}

export default SeoService;