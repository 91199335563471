import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';

const CustomNavbar = () => {
    const player = JSON.parse(sessionStorage.getItem('player'));

    const logout = () => {
        if (window.confirm('Voulez-vous vraiment vous déconnecter ?')) {
            sessionStorage.clear();
            window.location.href = '/login';
        }
    }

    return (
        <Navbar bg="light" expand="lg" fixed='top'>
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                        alt="Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/parier" id='navParier'>Parier</Nav.Link>
                        <Nav.Link as={Link} to="/classement" id='navClassement'>Classement</Nav.Link>
                        <Nav.Link as={Link} to="/matchs" id='navMatchs'>Matchs</Nav.Link>
                        <Nav.Link as={Link} to="/mes-pronostics" id='navPronostics'>Mes pronostics</Nav.Link>
                        {player.is_admin && (
                            <>
                                <Nav.Link as={Link} to="/admin" id='navAdmin'>Admin</Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav>
                        <Nav.Link className='text-uppercase' disabled>{player.pseudo}</Nav.Link>
                        <Nav.Link as={Link} to="#" onClick={logout}>Déconnexion</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
