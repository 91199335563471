import React, { useEffect, useState } from 'react';
import SeoService from '../services/seoService';
import { Link } from 'react-router-dom';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import FormatService from '../services/formatService';
import ApiService from '../services/apiService';
import CommunService from '../services/communService';
import Swal from 'sweetalert2';

const Parier = () => {
    const [matchs, setMatchs] = useState([]);
    const [loading, setLoading] = useState(true);

    const load = async () => {
        try {
            const response = await ApiService.getData('players/me/pronostics/available');
            let matchs = response.map(match => ({
                ...match,
                form: {
                    score_a: 0,
                    score_b: 0,
                    prolongation: '0',
                    tab: '0',
                    tab_a: 0,
                    tab_b: 0
                }
            }));
            setMatchs(matchs);
        } catch (error) {
            console.error('Error fetching matchs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        load();
    }, []);

    const handleChange = (e, matchIndex, field) => {
        const { value } = e.target;
        setMatchs(prevMatchs => {
            const updatedMatchs = [...prevMatchs];
            updatedMatchs[matchIndex].form[field] = value;
            return updatedMatchs;
        });
    };

    const validPronostic = (e, match) => {
        e.preventDefault();
        const form = match.form;

        //on vérifie les champs
        if (form.score_a === '' || isNaN(form.score_a)) {
            CommunService.errorPopup('Veuillez renseigner le score de' + match.team_a?.name);
            return;
        }
        if (form.score_a === '' || isNaN(form.score_b)) {
            CommunService.errorPopup('Veuillez renseigner le score de' + match.team_b?.name);
            return;
        }
        if (form.prolongation === '') {
            CommunService.errorPopup('Veuillez renseigner si prolongation');
            return;
        }
        if (form.prolongation === '1') {
            if (match.phase_id === 1) {
                CommunService.errorPopup('Il est possible de parier sur des prolongations uniquement si lors des phases finales !');
                return;
            }
            if (form.tab === '') {
                CommunService.errorPopup('Veuillez renseigner si tirs au but');
                return;
            }
            if (form.tab === '1') {
                if (form.score_a !== form.score_b) {
                    CommunService.errorPopup('Pour parier sur des tirs au but, le score doit être nul !');
                    return;
                }
                if (form.tab_a === '' || isNaN(form.tab_a)) {
                    CommunService.errorPopup('Veuillez renseigner si tirs au but pour ' + match.team_a?.name);
                    return;
                }
                if (form.tab_b === '' || isNaN(form.tab_b)) {
                    CommunService.errorPopup('Veuillez renseigner si tirs au but pour ' + match.team_b?.name);
                    return;
                }
                if (form.tab_a === form.tab_b) {
                    CommunService.errorPopup('Il n\'est pas possible de saisir un score nul pour les TAB !');
                    return;
                }
            }
        } else if (form.tab === '1') {
            CommunService.errorPopup('Il est possible de parier sur des tirs au but uniquement si vous avez parié sur des prolongations !');
            return;
        }

        Swal.fire({
            title: 'Confirmation',
            html: "Merci de valider votre pronostic.<br><strong><em>Il ne sera pas modifiable par la suite !</em></strong>",
            showCancelButton: true,
            confirmButtonText: "Valider mon pari",
            cancelButtonText: `Modifier mon pari`,
            confirmButtonColor: 'green'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.showLoading();
                ApiService.postData(`players/me/pronostics/matchs/${match.id}/validate`, match.form).then((response) => {
                    CommunService.successPopup(response);
                    load();
                });
            }
        });

    };

    if (loading) {
        return <p>Chargement en cours ...</p>;
    }

    SeoService.active('Parier');
    SeoService.setTitle('Parier');

    return (
        <div className='animate__animated animate__fadeIn'>
            <h1>Parier</h1>
            <p>Vous pouvez parier pour les matchs suivants :</p>
            {matchs.length === 0 && (
                <>
                    <Card className='text-center mb-4'>
                        <Card.Body>
                            <Card.Title>Rien à voir ici ...</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Vous avez déjà voté pour tous les matchs disponibles.</Card.Subtitle>
                            <Card.Text>
                                <img className='mt-3' height={150} src='https://upload.wikimedia.org/wikipedia/fr/thumb/0/0a/Logo_UEFA_Euro_2024.svg/1613px-Logo_UEFA_Euro_2024.svg.png' alt='Logo UEFA EURO 2024' />
                            </Card.Text>
                            <Card.Link as={Link} to="/mes-pronostics">Voir mes pronostics</Card.Link>
                        </Card.Body>
                    </Card>
                </>
            )}


            {matchs.map((match, index) => (
                <Card key={match.id} className='text-center mb-4 shadow'>
                    <Card.Body>
                        <Card.Title>{match.team_a?.name} - {match.team_b?.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{FormatService.datetime_fr(match.start_at)}</Card.Subtitle>
                        <Form onSubmit={(e) => { validPronostic(e, match) }}>
                            <Row className='align-items-center mt-4 text-start'>
                                <Col>
                                    <Form.Group className="mb-3" controlId={`score_a_${match.id}`}>
                                        <Form.Label>Score <strong>{match.team_a?.name}</strong></Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={match.form.score_a}
                                            onChange={(e) => handleChange(e, index, 'score_a')}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId={`score_b_${match.id}`}>
                                        <Form.Label>Score <strong>{match.team_b?.name}</strong></Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={match.form.score_b}
                                            onChange={(e) => handleChange(e, index, 'score_b')}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                {match.phase_id !== 1 && (
                                    <>
                                        <Col>
                                            <Form.Group className="mb-3" controlId={`prolongation_${match.id}`}>
                                                <Form.Label>Prolongation ?</Form.Label>
                                                <Form.Select
                                                    value={match.form.prolongation}
                                                    onChange={(e) => handleChange(e, index, 'prolongation')}
                                                    required
                                                >
                                                    <option value="1">Oui</option>
                                                    <option value="0">Non</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {match.form.prolongation === '1' && (
                                            <>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId={`tab_${match.id}`}>
                                                        <Form.Label>Tirs au but ?</Form.Label>
                                                        <Form.Select
                                                            value={match.form.tab}
                                                            onChange={(e) => handleChange(e, index, 'tab')}
                                                            required
                                                        >
                                                            <option value="1">Oui</option>
                                                            <option value="0">Non</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                {match.form.tab === '1' && (
                                                    <>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId={`tab_a_${match.id}`}>
                                                                <Form.Label>TAB <strong>{match.team_a?.name}</strong></Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    value={match.form.tab_a}
                                                                    onChange={(e) => handleChange(e, index, 'tab_a')}
                                                                    required={match.form.tab === '1'}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId={`tab_b_${match.id}`}>
                                                                <Form.Label>TAB <strong>{match.team_b?.name}</strong></Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    value={match.form.tab_b}
                                                                    onChange={(e) => handleChange(e, index, 'tab_b')}
                                                                    required={match.form.tab === '1'}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Row>
                            <Button variant="primary" type="submit" className='mt-3'>Valider votre pari</Button>
                        </Form>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

export default Parier;
