import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CustomNavbar from './Navbar';
import Home from './views/home';
import Parier from './views/parier';
import Classement from './views/classement';
import Matchs from './views/matchs';
import MesPronostics from './views/my-pronostics';
import LoginPage from './views/login';
import Regles from './views/regles';
import ApiService from './services/apiService';
import Admin from './views/admin/admin';
import Saisir from './views/admin/saisir';

function App() {
  const token = sessionStorage.getItem('token');
  const player = JSON.parse(sessionStorage.getItem('player'));
  const actualRoute = window.location.pathname;
  let userConnected = token && player;


  if (!userConnected) {
    sessionStorage.clear();
    if (actualRoute !== '/login') {
      window.location.href = '/login';
    }
  } else {
    if (actualRoute === '/login') {
      window.location.href = '/';
    } else {
      ApiService.getData('auth').catch((error) => {
        sessionStorage.clear();
        userConnected = false;
        window.location.reload();
        console.error(error);
      });
    }
  }

  return (
    <Router>
      {userConnected && (
        <>
          <CustomNavbar />
        </>
      )}
      <div className="App">
        <div className="container mt-4">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            {userConnected && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/parier" element={<Parier />} />
                <Route path="/mes-pronostics" element={<MesPronostics />} />
                <Route path="/classement" element={<Classement />} />
                <Route path="/matchs" element={<Matchs />} />
                <Route path="/regles" element={<Regles />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/saisir" element={<Saisir />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
